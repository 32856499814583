<template>
  <div>
    <van-nav-bar :left-arrow="true" left-text="返回" title="开放影院规则"  fixed :placeholder="true" @click-left="goBack"/>
    <div class="home-wrap" >
      <div class="main-card-wrap" v-for="item in tableData" >
        <div style="border-bottom: 1px  dashed #ccc;margin-bottom: 10px">
          <van-cell center :title="'('+item.id+')'+item.name"  >
            <template #right-icon>
              <van-switch v-model="item.status" size="24" active-value="Y" inactive-value="N" @change="setStatus(item.id,item.status)"/>
            </template>
          </van-cell>
          <van-collapse v-model="activeNames">
            <van-collapse-item title="城市" :name="item.id">{{ item.cityNames?item.cityNames:'无' }}</van-collapse-item>
            <van-collapse-item title="院线" :name="item.id">{{ item.lineNames?item.lineNames:'无' }}</van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api-service'
export default {
  name: "Rule-Detail",
  data(){
    return {
      tableData:[],
      checked:true,
      activeNames:['1']
    }
  },
  created() {
    this.fetchData();
  },
  methods:{
    fetchData(){
      Api.getCinemaRuleList().then(res=>{
        this.tableData = res;
      })
    },
    setStatus(id,status){
      Api.setCinemaRuleStatus({id:id,status:status}).then(res=>{
        this.$notify({type:'success',message:'操作成功'});
        this.fetchData();
      })
    },
    goBack(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">
.home-wrap {
  height: 100%;
  background-color: #f3f3f3;

  .main-card-wrap {
    background-color: white;
    -moz-box-shadow: 2px 2px 3px #ccc;
    -webkit-box-shadow: 2px 2px 3px #ccc;
    box-shadow: 2px 2px 3px #ccc;
    .left {
      .title {
        font-size: 16px;
      }
      .btn {
        height: 34px;
        width: 120px;
        color: white;
        margin-top: 6px;
        border-radius: 4px;
        background-color: #8565bd;
      }
    }
    .right {
      margin-right: 8px
    }
  }
}
</style>
